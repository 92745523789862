$(function(window) {


    var $form = $('form.ggroup'),
    validateMessage = "There was an error submitting this form.",
    notifyMessage = $('#notify .overlay-message');


    var notify = function(message){
        notifyMessage.text(message);

        showNotify();
        setTimeout(function(e){
            closeNotify();
        }, 2500)
    }

    var showNotify = function(){
        $('#notify').css('display', 'flex');
        setTimeout(function(e){
            $('#notify').css('opacity', '1');            
        }, 200)
    }

    var closeNotify = function(){
        $('#notify').css('opacity', '0');
        setTimeout(function(e){
            $('#notify').css('display', 'none');            
        }, 400);
    }

    $('#notify').on('click', function(e){
        closeNotify();
    })    

    $('.compare .slider').twentytwenty({
        //default_offset_pct: 0.7, // How much of the before image is visible when the page loads
        orientation: 'horizontal', // Orientation of the before and after images ('horizontal' or 'vertical')
        before_label: '', // Set a custom before label
        after_label: '', // Set a custom after label
        no_overlay: true, //Do not show the overlay with before and after
        move_slider_on_hover: false, // Move slider on mouse hover?
        move_with_handle_only: true, // Allow a user to swipe anywhere on the image to control slider movement. 
        click_to_move: false // Allow a user to click (or tap) anywhere on the image to move the slider to that location.
    });


    $('#register form input').on('change focus', function(e){
        $(this).parent().addClass('label-above');
    })

    $('#register form input').on('blur', function(e){
        //console.log($(this).val())
        if( $(this).val() == '' || $(this).val() == null ){
            $(this).parent().removeClass('label-above');
        }
    })

    $('a[href="#register"]').on('click', function(e){
        e.preventDefault();
        e.stopImmediatePropagation();
        dataLayer.push({'event': 'registerButtonClick'});
        $('.modal').addClass('open');
    })

    $('[data-modal-close]').on('click', function(e){
        e.preventDefault();
        e.stopImmediatePropagation();
        closeModal();
    })

    function openModal(){
        $('html').addClass('modal-open');
        $('#register.modal').addClass('open');
    }

    function closeModal(){
        $('html').removeClass('modal-open');
        $('#register.modal').removeClass('open');
    }


    var postFormData = function () {
        var request = $.ajax({
            url: "lib/process.php",
            method: "POST",
            data: $form.serialize()
        });

        request.done(function (msg) {

            $('#notify').addClass('success');

            dataLayer.push({'event': 'formSubmission'});            
            notify('Thank you for your inquiry.');
            closeModal();
            $form[0].reset();

            return true;
        });

        request.fail(function (jqXHR, textStatus) {
            alert("There was an error with your submission. Please try again later.<br><br>" + textStatus);
        });
    }


    var validateForm = function(){

        if ( $('[name="suite_is"]').val().length >= 1 ) {
            validateMessage = "No Sneaky Stuff";
            return false;
        }

        if( $('[name="space_type[]"]:checked').length == 0 ){
            validateMessage = "Please select the type of space you are looking for";
            return false;
        }

        return true;
    }


    $form.on('submit', function (e) {
        e.preventDefault();

        if( !$(this).hasClass('submitted') ){
            if(validateForm()){
                postFormData();
            } else {
                notify(validateMessage);
            }
        }

    });


    var swiper = new Swiper('.swiper-container', {
      spaceBetween: 30,
      centeredSlides: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
    

});